import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'class-names';

import InfoComic from './Info';
import './Comic.css';
// import { CircularProgress } from '@material-ui/core';
// import { Skeleton } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
	parent: {
		position: 'relative',
		border: '1px solid black',
		height: '100%',
		width: '100%'
	},
	item: {
		overflow: 'hidden',
		position: 'relative'
	},
	image: {
		width: '100%',
		height: '100%',
		objectFit: 'cover',
		// backgroundColor: '#f7f7f7',
		backgroundColor: 'rgb(255 255 255 / 15%)',
		marginBottom: '-4px'
	},
	imagePlaceholder: {
		width: '100%',
		height: '100%',
		minHeight: '25vw',
		backgroundColor: 'rgb(255 255 255 / 15%)',
		'&:after': {
			backgroundImage: 'linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.04), transparent)'
		}
	},
	raised: {
		boxShadow: '2px 2px 6px -2px black'
	},
	footer: {
		position: 'absolute',
		boxSizing: 'border-box',
		minHeight: '60px',
		maxHeight: '100%',
		width: '100%',
		bottom: '0',
		backgroundColor: '#00000090',
		padding: '8px',
		color: '#fff',
		display: 'flex',
		alignItems: 'center',
		flexDirection: 'row',
		overflow: 'hidden',
		textOverflow: 'ellipsis'
	},
	title: {
		fontSize: '1rem',
		lineHeight: '24px'
	},
	author: {
		fontSize: '0.75rem',
		lineHeight: '1',
		fontStyle: 'italic'
	},
	clickable: {
		cursor: 'pointer'
	},
	badge: {
		backgroundColor: '#f42',
		color: '#fff',
		position: 'absolute',
		top: '-8px',
		right: '-8px',
		width: '30px',
		height: '30px',
		borderRadius: '30px',
		textAlign: 'center',
		lineHeight: '30px',
		boxShadow: '1px 1px 4px black'
	}
}));

function ComicImage(props) {
	const classes = useStyles();
	const comic = props.comic;
	return <img
		className={classNames(
			classes.image,
			props.onClick && classes.clickable
		)}
		// src={`data:image/jpeg;base64,${comic.image}`}
		src={`/static/images/${comic.id}.jpg`}
		alt={comic.title}
		onClick={props.onClick}
	/>;
}

/* function ComicImagePlaceholder(props) {
	const classes = useStyles();
	return <Skeleton className={classes.imagePlaceholder} animation='wave' variant='rect' onClick={props.onClick} />;
} */

export default function Comic(props) {
	const classes = useStyles();

	const comic = props.comic || {};

	return (
		<div
			className={classNames(classes.parent)}
			style={{ position: 'relative' }}
		>
			<div
				className={classNames(
					classes.item,
					props.hasHover && 'comic-item-hoverable',
					props.raised && classes.raised,
					props.className
				)}
				style={props.style}
			>
				{/* {comic.image ?
					<ComicImage
						comic={comic}
						onClick={props.onClick}
					/> : <ComicImagePlaceholder onClick={props.onClick} />} */}
				<ComicImage comic={comic} onClick={props.onClick} />
				<div className={classNames('comic-item-footer', classes.footer)} >
					<div>
						<div className={classes.title}>{comic.title}</div>
						<div className={classes.author}>{`di: ${comic.author}`}</div>
					</div>
					{props.actions}
				</div>

				{props.open ?
					<InfoComic
						comic={comic}
						open
						onClose={props.onClose}
						actions={[
							...props.actions,
							...props.openActions
						]}
					/> : null}
			</div>
			{props.rank ?
				<div className={classes.badge}>{props.rank}</div>
				: null}
		</div>
	);
}

Comic.defaultProps = {
	actions: [],
	openActions: [],
	hasHover: true
};

/* 	<div className={classNames(classes.paper, classes.paperXS)} >
			{title(comic.title)}
			{image(comic)}
			{description(comic.description)}
			<AmazonButton style={{ margin: '3px' }}>Vota</AmazonButton>
		</div> */
