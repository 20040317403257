import axios from 'axios';

export function getComics(id) {
	return axios.get('/api/comics' + (id ? `/${id}` : ''));
}

export function getComicImages(id) {
	return axios.get('/api/comic_images' + (id ? `/${id}` : ''));
	// return axios.get('/static/images' + (id ? `/${id}.jpg` : ''));
}

export function loadComics(onImage) {
	// let delay = 1000;
	return new Promise((resolve, reject) => {
		getComics()
		.then((res) => {
			resolve(res);
			if (onImage) {
				for (const comic of res.data) {
					getComicImages(comic.id)
					.then((res) => {
						onImage(comic.id, res.data.image);
						// setTimeout(() => onImage(comic.id, res.data.image), delay);
						// delay += 1000;
					})
					.catch((err) => {
						console.error(err);
						onImage(comic.id, '');
					});
				}
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}


export function getRanking(userID) {
	// return axios.get('/api/votes/ranking' + (tokenID ? `/${tokenID}` : ''));
	return axios.get('/api/votes/ranking', {
		params: {
			userID
		}
	});
}

export function loadRanking(userID, onImage) {
	// let delay = 1000;
	return new Promise((resolve, reject) => {
		getRanking(userID)
		.then((res) => {
			resolve(res);
			if (onImage) {
				for (const comic of res.data) {
					// console.log('Getting comic image', comic);
					getComicImages(comic.id)
					.then((res) => {
						onImage(comic.id, res.data.image);
						// setTimeout(() => onImage(comic.id, res.data.image), delay);
						// delay += 1000;
					})
					.catch((err) => {
						console.error(err);
						onImage(comic.id, '');
					});
				}
			}
		})
		.catch((err) => {
			reject(err);
		});
	});
}

export function postVote(comicID, tokenID) {
	const request = axios.post('/api/votes/vote', { comicID, tokenID });
	request
	.then((res) => {
		// console.log(`You just voted for Comic ${comicID}`);
		window.location.href = '/';
		return res;
	})
	.catch((err) => {});
	return request;
}

export function checkVote(tokenID) {
	const request = axios.post('/api/votes/vote', { comicID: null, tokenID });
	request
	/* .then((res) =>{
		// console.log('Checking if user already voted...');
		return res;
	}) */
	.catch(() => {});
	return request;
}

export function login() {}
export function logout() {}
export function refresh() {}
