import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'class-names';
import {
	useMediaQuery,
	Grid,
	Modal
} from '@material-ui/core';

// import AmazonButton from '../components/AmazonButton';
import amazonLogo from '../static/amazon.png';
import loginIcon from '../static/login-icon.png';
import comicsAwardLogo from '../static/logo-amazon-comic-award-placeholder.png';
import footer from '../static/footer.png';
import bannerSelection from '../static/bannerCollection.png';

const useStyles = makeStyles((theme) => ({
	container: {
		boxSizing: 'border-box',
		width: '100%',
		display: 'flex',
		justifyContent: 'space-evenly',
		alignItems: 'center',
		flexDirection: 'column',
		padding: '25px 0 10px 0',
		backgroundImage: `url(${footer})`,
		backgroundPositionX: 'center'
	},
	allBannerContainer: {
		height: '30vh',
		maxHeight: '130px',
		marginBottom: '20px',
		position: 'relative',
		color: 'black'

	},
	allBannerImage: {
		objectFit: 'cover',
		height: '100%',
		padding: '0'
	},
	white: {
		color: '#fff'
	},
	black: {
		color: '#111'
	},
	blue: {
		color: '#fbb03b'
	},
	link: {
		color: '#111',
		textDecoration: 'none',
		'&:hover': {
			textDecoration: 'underline'
		}
	},
	light: {
		fontSize: '14px',
		fontWeight: 100
		// fontFamily: 'AmazonEmberDisplay-Light'
	},
	medium: {
		// fontFamily: 'AmazonEmberDisplay-Medium',
		fontSize: '14px',
		fontWeight: 400
	},
	bannerText: {
		//fontFamily: 'AmazonEmberDisplay-Medium',
		fontSize: '1.5em',
		fontWeight: 400,
		position: 'absolute',
		margin: '0',
		left: '0',
		right: '0',
		textAlign: 'center',
		top: '50%',
		transform: 'translateY(-50%)'
	}
}));

const divider = <span style={{ margin: '0 10px' , color : '#111' }}  >|</span>;
function Footer(props) {
	// const isXS = useMediaQuery('(max-width:600px)');
	const classes = useStyles();

	return ([
		<a className={classes.allBannerContainer} key='all_banner' href='https://www.amazon.it/b/ref=s9_acss_bw_cg_GeekSt_5a1_w?ie=UTF8&node=27412553031&pf_rd_m=A2VX19DFO3KCLO&pf_rd_s=merchandised-search-7&pf_rd_r=2V2MZE9GX8K1ABRXZMV4&pf_rd_t=101&pf_rd_p=ed69c02f-5c60-45bb-b3a4-8060c904cb3d&pf_rd_i=26047947031'>

			<p className={classes.bannerText}> <b> Clicca qui </b> per accedere a tutta la selezione di fumetti di <b> Lucca Comics&Games 2024 </b>	</p>
			<img className={classes.container + ' ' + classes.allBannerImage} style={{ margin: '10px 0px 10px 0px' }} src={bannerSelection} />

		</a>
		,
		<div key='footer' className={classes.container}>
			<div>
				<img src={amazonLogo} style={{ height: '30px' }} className='img img-responsive' />
			</div>
			<div>
				<p className={classes.white}>
					<a target='_blank' href='../AmazonComicsAward_2024Edition_PublisherTCs.pdf' className={classNames(classes.link, classes.medium)}>Termini e Condizioni “Amazon Comics Award 2024"</a>
					{divider}
					<a target='_blank' href='../AmazonComicsAward_2024Edition_ConsumerTCs.pdf' className={classNames(classes.link, classes.medium)}>Termini e Condizioni “Vinci un Buono Sconto Amazon.it da 100€ con l’Amazon Comics Award 2024”</a>
					{divider}
					<a target='_blank' href='../Privacy_policy_e_cookies.pdf' className={classNames(classes.link, classes.medium)}>Privacy policy</a>
					{divider}
					<a target='_blank' href='../Privacy_policy_e_cookies.pdf' className={classNames(classes.link, classes.medium)}>Informativa sui cookie</a></p>
			</div>
			<div>
				<p className={classNames(classes.black, classes.medium)}>
					© 2022-2024 Amazon Comics Award - Powered by <a
						className={classes.link}
						href='https://www.aitek.it'
						target='_blank'
					> Aitek S.p.A. </a> - Genova - P.IVA: 02804450100
				</p>
			</div>
		</div>
	]);
}

export default Footer;
