import React from 'react';
/* import {
	useHistory,
	useLocation
} from 'react-router-dom'; */
import { UserContext } from '../contexts';
// import * as backend from './backend';

function setLocal(param, value) {
	if (value !== undefined) {
		localStorage.setItem(param, value);
	} else {
		localStorage.removeItem(param);
	}
}

function AuthManager(props) {
	const [token, _setToken] = React.useState(localStorage.getItem('AMZ_token'));
	const [userName, _setUserName] = React.useState(localStorage.getItem('AMZ_user_name'));
	const [userID, _setUserID] = React.useState(localStorage.getItem('AMZ_user_id'));

	const setToken = (value, ...rest) => (setLocal('AMZ_token', value), _setToken(value, ...rest));
	const setUserName = (value, ...rest) => (setLocal('AMZ_user_name', value), _setUserName(value, ...rest));
	const setUserID = (value, ...rest) => (setLocal('AMZ_user_id', value), _setUserID(value, ...rest));

	function logout() {
		try {
			window.amazon.Login.logout();
		} catch (err) {}
		setToken();
		setUserID();
		setUserName();
		window.location.href = '/';
	}

	function login() {
		var options = {};
		options.popup = false;
		options.scope = 'profile';
		options.response_type = 'code';
		options.pkce = true;
		options.scope_data = {
			profile: { essential: false }
		};

		window.amazon.Login.authorize(options, window.location.origin + '/login');
	}

	const getUserInformations = (code) => new Promise((resolve, reject) => {
		window.amazon.Login.retrieveToken(code, function(resp) {
			if (resp.error) {
				// console.error('Error: ' + resp.error);
				logout();
				return reject(resp.error);
			}
			// console.log(resp.expires_in);
			window.amazon.Login.retrieveProfile(resp.access_token, function(response) {
				if (response.error) {
					logout();
					window.location.reload();
					return;
				} else {
					if (response.success && response.profile) {
						// console.log("PROFILO AMAZON ", response)
						setToken(resp.access_token);
						setUserID(response.profile.CustomerId);
						setUserName(response.profile.Name);
					}
					resolve(resp.access_token);
				}
			});
		});
	});

	function checkSession() {
		if (token) {
			// refresh token

		}
	}

	return (
		<UserContext.Provider value={{
			token,
			userID,
			userName,
			isAuthenticated: !!token,
			login,
			getUserInformations,
			logout,
			checkSession
		}}
		>{props.children}</UserContext.Provider>
	);
}

export default AuthManager;
