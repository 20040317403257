import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	useMediaQuery,
	Grid,
	Modal
} from '@material-ui/core';

// import AmazonButton from '../components/AmazonButton';
import amazonLogo from '../static/amazon.png';
import loginIcon from '../static/login-icon.png';
import comicsAwardLogo from '../static/logo-amazon-comic-award-placeholder.png';

import { UserContext } from '../contexts';

const useStyles = makeStyles((theme) => ({
	container: {
		boxSizing: 'border-box',
		width: '100%',
		display: 'flex',
		justifyContent: 'space-between',
		flexDirection: 'row',
		padding: '15px',
		backgroundColor: '#232f3e',
		overflow: 'hidden'
	},
	button: {
		borderRadius: '3px',
		padding: '10px 25px',
		margin: '5px 15px 5px 5px',
		color: '#fff',
		backgroundBlendMode: 'overlay',
		backgroundColor: '#232f3e',
		border: '1px solid #fff',
		'&:hover': {
			color: '#232f3e',
			backgroundColor: '#fff',
			boxShadow: '0 3px 10px 5px rgba(0, 0, 0, 0.1)'
		}
	},
	greet: {
		borderRadius: '3px',
		padding: '10px 25px',
		margin: '5px 15px 5px 5px',
		color: '#fff',
		display: 'flex',
		justifyContent: 'flex-start'
		// alignItems: 'center'
	},
	left: {
		display: 'flex',
		justifyContent: 'flex-start',
		alignItems: 'center'
	},
	right: {
		display: 'flex',
		justifyContent: 'flex-end',
		alignItems: 'center'
	}
}));

function Header(props) {
	const isXS = useMediaQuery('(max-width:500px)');
	const classes = useStyles();

	const amzLogo = <a target='_blank' href='//www.amazon.it'><img src={amazonLogo} style={{ height: '30px' }} className='img img-responsive' /></a>;
	return <UserContext.Consumer>{({ isAuthenticated, userName, login, logout }) => (
		<div className={classes.container}>
			<div className={classes.left}>
				{isXS ? amzLogo : <a href='/'><img src={comicsAwardLogo} style={{ height: '30px' }} className='img img-responsive' /></a>}
			</div>

			{isAuthenticated ? [
				<div className={classes.container, classes.greet} key={'name'}>Ciao, {userName}!</div>,
				<div key={'logout'} className={classes.right}>
					<button id={'logout-button'} className={classes.button} onClick={() => logout()}>{/* <img src={loginIcon} /> */}Esci</button>
					{isXS ? null : amzLogo}
				</div>
			] : <div className={classes.right}>
				<button id={'login-button'} className={classes.button} onClick={() => login().then((response) => console.log(response))}>{/* <img src={loginIcon} /> */}Accedi</button>
				{isXS ? null : amzLogo}
			</div>}
		</div>
	)}</UserContext.Consumer>;
}

export default Header;
