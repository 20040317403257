import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
	useMediaQuery,
	Grid,
	Modal
} from '@material-ui/core';
import Header from './Header';
import Footer from './Footer';
import './App.css';
import bannerHome from '../static/headerHome.png';
import bannerRank from '../static/headerPodium.png';

const useStyles = makeStyles((theme) => ({
	body: {
		display: 'flex',
		justifyContent: 'center',
		flexDirection: 'column',
		alignItems: 'center',
		color: 'white',
		backgroundColor: 'black'
	},
	content: {
		maxWidth: '1500px',
		width: '100%',
		boxSizing: 'border-box',
		overflow: 'hidden'
	},
	paper: {
		display: 'flex',
		position: 'absolute',
		maxWidth: 800,
		backgroundColor: theme.palette.background.paper,
		border: '2px solid #000',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		flexDirection: 'row-reverse',
		justifyContent: 'center',
		alignItems: 'center'
	},
	banner: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        maxHeight: '310px',
        height: '36vw'
    },
	bannerImage: {
		width: '100%',
		height: '100%',
		objectFit: 'cover'
	},
	adContainer: {
		display: 'flex',
		height: '100px'
	},
	advertisment: {
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		width: '100%',
		margin: '10px',
		backgroundColor: '#8a8a8a'
	}
}));

function App(props) {
	// const isXS = useMediaQuery('(max-width:600px)');
	const classes = useStyles();

	return (
		<div className={classes.body}>
			<Header />
			<div className={classes.content}>
				<div className={classes.banner}>
					<img className={classes.bannerImage} style={{ margin: '10px 0px 10px 0px' }} src={window.location.pathname === '/' ? bannerHome : bannerRank} />
				</div>
				{props.children}
			</div>
			<Footer />
		</div>
	);
}

export default App;
