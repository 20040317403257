/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React from 'react';
import {
	BrowserRouter as Router,
	Switch,
	Route,
	Redirect
} from 'react-router-dom';

import * as structure from './structure';

import { UserContext } from '../contexts';
import AuthManager from '../utils/auth';

import App from '../layout/App';

function withLayout(component, { appBar = true, drawer = true, ...config }) {
	return <App
		layout={{
			appBar,
			drawer,
			...config
		}}
	>{component}</App>;
}

export default function _Router() {
	function PrivateRoute({ children, ...rest }) {
		return (
			<Route
				{...rest}
				render={({ location }) => <UserContext.Consumer>{({ isAuthenticated }) => (
					isAuthenticated ? children : <Redirect to={{ pathname: '/', state: { from: location } }} />
				)}</UserContext.Consumer>}
			/>
		);
	}

	function LoginRoute(props) {
		if (props.disabled || !props.component) return null;
		const Component = props.component;
		return <Route path={props.path}>
			{<UserContext.Consumer>{({ isAuthenticated }) => (
				isAuthenticated ?
					<Redirect to={{ pathname: '/' }} /> :
					<Component />
			)}</UserContext.Consumer>}
		</Route>;
	}
	LoginRoute.defaultProps = {
		path: '/login'
	};

	function LoadingRoute(props) {
		if (props.disabled || !props.component) return null;
		const Component = props.component;
		return <Route path={props.path}>
			{withLayout(<Component />, { appBar: false, drawer: false, layout: false })}
		</Route>;
	}
	LoadingRoute.defaultProps = {
		path: '/loading'
	};

	function DefaultRoute(props) {
		if (props.redirect) return <Redirect to={{ pathname: props.redirect === true ? '/' : props.redirect }}	/>;
		if (props.disabled || !props.component) return null;
		const Component = props.component;
		return <Route>
			{withLayout(<Component />, { appBar: false, drawer: false })}
		</Route>;
	}
	// DefaultRoute.defaultProps = {};

	function GenericRoute(props) {
		if (props.redirect) return <Redirect to={{ pathname: props.redirect === true ? '/' : props.redirect }}	/>;
		if (props.disabled || !props.component) return null;
		const Component = props.component;
		if (props.private) {
			return <PrivateRoute path={props.path} exact={props.exact} >
				{withLayout(<Component />, { appBar: props.appBar, drawer: props.drawer })}
			</PrivateRoute>;
		} else {
			return <Route path={props.path} exact={props.exact}>
				{withLayout(<Component />, { appBar: props.appBar === true, drawer: props.drawer === true })}
			</Route>;
		}
	}
	GenericRoute.defaultProps = {
		private: true
	};

	return (
		<Router>
			<AuthManager>
				<Switch>
					<LoginRoute {...structure.login} />
					<LoadingRoute {...structure.loading} />
					{structure.routes.map((route, index) => ((route.skip || route.divider) ? null : <GenericRoute key={`route-${index}`} {...route} />))}
					<DefaultRoute {...structure.notFound} />
				</Switch>
			</AuthManager>
		</Router>
	);
}
