/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
// import classNames from 'class-names';
import { getComics, postVote, checkVote, login, loadComics } from '../../utils/backend';

import {
	CircularProgress,
	Grid
	// GridList,
	// GridListTile,
	// GridListTileBar
} from '@material-ui/core';

import AmazonButton from '../../components/AmazonButton';
// import InfoComic from '../../components/Comics/Info';
import Comic from '../../components/Comics/Comic';
import { UserContext } from '../../contexts';

class Home extends Component {
	static contextType = UserContext;

	constructor(props, context) {
		super(props, context);
		this.state = {};

		this.vote = this.vote.bind(this);
	}

	componentDidMount() {
		const { isAuthenticated, token, logout } = this.context;

		if (isAuthenticated) {
			checkVote(token)
				.then((res) => {
					if (res.data) {
						this.setState({ alreadyVoted: true });
					} else {
						this.setState({ alreadyVoted: false });
					}
				})
				.catch(() => {
					this.setState({ alreadyVoted: false });
					logout();
				});
		} else {
			this.setState({ alreadyVoted: false });
		}

		this.loadComics();
	}

	loadComics() {
		getComics()
			.then((res) => {
				this.setState({ comics: res.data });
			})
			.catch(() => {
				this.setState({ comics: [], comicListError: 'Si è verificato un errore recuperando i dati, riprova più tardi!' });
			});
	}

	vote(target) {
		const { isAuthenticated, token, login } = this.context;
		if (isAuthenticated) {
			return postVote(target, token);
		} else {
			localStorage.setItem('next_vote', target);
			return login();
		}
	}

	renderComics() {
		if (!this.state.comics || this.state.alreadyVoted === undefined) {
			return <div style={{
				width: '100%',
				height: '300px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}
			>
				<CircularProgress style={{ width: '80px', height: '80px', color: '#f59618' }} />
			</div>;
		} else if (this.state.comicListError) {
			return (
				<div
					className='Body'
					style={{
						color: 'white',
						backgroundColor: 'black',
						width: '100%',
						height: '300px',
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						alignItems: 'center'
					}}
				>
					<h3 className='page-title'>
						{this.state.comicListError}
					</h3>
				</div>
			);
		} else {
			return <Grid container spacing={2} style={{ padding: '10px' }}>
				{this.state.comics.map((comic) => (
					<Grid item xs={6} sm={4} md={3} lg={3} key={comic.id} >
						<Comic
							raised
							style={{ height: '100%' }}
							comic={comic}
							onClick={() => { this.setState({ open: comic.id }); /* console.log(comic); */ }}
							onClose={() => { this.setState({ open: false }); }}
							open={this.state.open === comic.id}
							openActions={[
								<AmazonButton.BuyButton
									key='buy-button'
									onClick={() => { window.location.href = comic.url; }}
								/>,
								/* chiusura voti */
								<UserContext.Consumer key='usr-context'> 
									{(value) =>
									(<AmazonButton.VoteButton
										key='vote-button'
										label={value.isAuthenticated ?
											(this.state.alreadyVoted ? 'Hai già votato! Vai alla Home' : 'Vota questo fumetto')
											: 'Accedi per votare'}
										onClick={() => {
											if (this.state.alreadyVoted) {
												window.location.href = '/';
											} else if (value.isAuthenticated) {
												this.vote(comic.id)
													.catch(() => {
														this.setState({ alreadyVoted: true });
													});
											} else {
												value.login();
											}
										}}
									/>)}
								</UserContext.Consumer> /* chiusura voti  */
							]}
						/>
					</Grid>
				))}
			</Grid>;
		}
	}

	render() {
		return (
			<div className='Body'>
				<h3 className='page-title'>
					Alla ricerca del titolo più amato dai lettori: vota qui il tuo fumetto preferito!
					<br />
					Al termine delle votazioni, avrai la possibilità di essere estratto a sorte per la vincita di un Buono Sconto Amazon dal valore di €100. 
					<br />Hai tempo fino al 3 Novembre per votare.
				</h3>
				<div className='ComicGrid'>
					{this.renderComics()}
				</div>
			</div>
		);
	}
}

export default Home;
