import React from 'react';
import classNames from 'class-names';
import './AmazonButton.css';

function AmazonButton(props) {
	return (
		<span className={classNames('amz-button', props.className, props.bsStyle && 'amz-st-' + props.bsStyle)} style={props.style}>
			<span className={'amz-button-inner'}>
				<a href={props.href} onClick={props.onClick}>{props.children}</a>
			</span>
		</span>
	);
}

AmazonButton.VoteButton = function(props) {
	return (
		<AmazonButton
			{...props}
			style={{ margin: '3px', width: '100%', ...props.style }}
		>{props.label ? props.label : 'Vota!'}</AmazonButton>
	);
};
AmazonButton.VoteButton.displayName = 'AmazonVoteButton';

AmazonButton.BuyButton = function(props) {
	return (
		<AmazonButton
			{...props}
			style={{ margin: '3px', width: '100%', ...props.style }}
			bsStyle='darker'
		>Acquista ora</AmazonButton>
	);
};
AmazonButton.BuyButton.displayName = 'AmazonBuyButton';

export default AmazonButton;
