/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';
// import classNames from 'class-names';
import { getRanking } from '../../utils/backend';

import {
	Grid,
	CircularProgress
	// GridList,
	// GridListTile,
	// GridListTileBar,
	// Badge
} from '@material-ui/core';

import AmazonButton from '../../components/AmazonButton';
import Comic from '../../components/Comics/Comic';
import Podium from '../../components/Comics/Podium';
import { UserContext } from '../../contexts';

/* function hasUserVoted() {
	return false;
} */

class Home extends Component {
	static contextType = UserContext;

	constructor(props, context) {
		super(props, context);
		this.state = {};
	}

	componentDidMount() {
		// chiusura voti
		const { isAuthenticated, userID } = this.context;
		if (isAuthenticated) {
			getRanking(userID)

			getRanking()
				.then((res) => {
					this.setState({ comics: res.data, rankingError: false });
				})
				.catch((err) => {
					console.log(err);
					if ((err.response.status >= 400) && (err.response.status < 500)) {
						this.setState({ comics: [], rankingError: 'Devi prima aver votato per vedere la classifica!' });
					} else {
						this.setState({ comics: [], rankingError: 'Si è verificato un errore recuperando i dati, riprova più tardi!' });
					}
				});
			// chiusura voti
		} else {
			window.location.href = '/';
		}
	}

	renderPodium() {
		return <Podium comics={this.state.comics} />;
	}

	renderRunnerUps() {
		return <Grid container spacing={2} style={{ padding: '10px' }}>
			{this.state.comics.slice(3).map((comic, index) => (
				<Grid item xs={3} md={3} lg={2} key={comic.id} >
					<Comic
						raised
						style={{ height: '100%' }}
						comic={comic}
						onClick={() => { this.setState({ open: comic.id }); /* console.log(comic); */ }}
						onClose={() => { this.setState({ open: false }); }}
						open={this.state.open === comic.id}
						openActions={[
							<AmazonButton.BuyButton
								key='buy-button'
								onClick={() => { window.location.href = comic.url; }}
							/>
						]}
						rank={index + 4 + '°'}
					/>
				</Grid>
			))}
		</Grid>;
	}

	render() {
		if (!this.state.comics) {
			return <div style={{
				width: '100%',
				height: '300px',
				display: 'flex',
				justifyContent: 'center',
				alignItems: 'center'
			}}
			>
				<CircularProgress style={{ width: '80px', height: '80px', color: '#f59618' }} />
			</div>;
		}

		if (this.state.rankingError) {
			return (
				<div
					className='Body'
					style={{
						color: 'white',
						backgroundColor: 'black',
						width: '100%',
						height: '300px',
						display: 'flex',
						justifyContent: 'center',
						flexDirection: 'column',
						alignItems: 'center'
					}}
				>
					<h3 className='page-title'>
						{this.state.rankingError}
					</h3>
					<AmazonButton
						key='redirect-button'
						style={{
							margin: '8px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							width: '100%',
							backgroundColor: '#000',
							borderColor: '#000'
						}}
						onClick={() => { window.location.href = '/'; }}
					>
						Torna alla lista dei fumetti
					</AmazonButton>
				</div>
			);
		} else {
			return (
				<div className='Body' style={{ color: 'white', backgroundColor: 'black' }}>
					<h3 className='page-title'>
						Grazie per aver votato!
					</h3>
					<h4 className='page-title'>
						La classifica:
					</h4>
					<div>
						{this.renderPodium()}
					</div>
					<div>
						{this.renderRunnerUps()}
					</div>
				</div>
			);
		}
	}
}

export default Home;
