import Home from './Home';
import Ranking from './Ranking';

import Login from './Login';
import Loading from './Loading';
import NotFound from './NotFound';

import ConfigIcon from '@material-ui/icons/Settings';
import UserIcon from '@material-ui/icons/Person'; // AccountCircle
import RuntimeIcon from '@material-ui/icons/Dvr';

export const routes = [
	{
		name: 'Home',
		icon: '',
		path: '/',
		private: false,
		exact: true,
		component: Home
	},
	{
		name: 'Ranking',
		icon: '',
		path: '/ranking',
		private: true,
		exact: true,
		component: Ranking
	}
];

export const login = {
	component: Login
};

export const loading = {
	component: Loading
};

export const notFound = {
	component: NotFound
	// redirect: '/'
};
