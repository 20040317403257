import React from 'react';
import ReactDOM from 'react-dom';

import * as serviceWorker from './serviceWorker';

import './styles/index.css';
// import './styles/Core.scss';

import Routes from './routes';

/* This function sets the Amazon client ID */
window.onAmazonLoginReady = function() {
	// console.log('CLIENT-ID: ', process.env.CLIENT_ID);
	// console.log('PORT: ', process.env.PORT);
	window.amazon.Login.setClientId(process.env.CLIENT_ID || 'amzn1.application-oa2-client.57fa58fa7cf3428ba4ec7c582f40331f');
	// window.amazon.Login.setClientId(clientId);
	window.amazon.Login.setLanguage('it');
	window.amazon.Login.setRegion(window.amazon.Login.Region.Europe);
};

/* This function imports Amazon SDK */
(function(d) {
	var a = d.createElement('script'); a.type = 'text/javascript';
	a.async = true; a.id = 'amazon-login-sdk';
	a.src = 'https://assets.loginwithamazon.com/sdk/na/login1.js';
	d.getElementById('amazon-root').appendChild(a);
})(document);

ReactDOM.render(<Routes />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

/*
import React from 'react';
import ReactDOM from 'react-dom';
import './styles/index.css';
import App from './layout/App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
	<React.StrictMode>
		<App />
	</React.StrictMode>,
	document.getElementById('root')
);

serviceWorker.unregister();
 */
