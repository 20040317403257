import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
//import classNames from 'class-names';
import {
	useMediaQuery,
	Grid,
	Modal,
	Dialog
} from '@material-ui/core';

import Comic from './Comic';
import podiumImage from '../../static/rankingbg.png'

const useStyles = makeStyles((theme) => ({
	podium: {
		display: 'flex',
		alignItems: 'flex-end'
	},
	item: {
		width: '100%'
	},
	comic: {
		padding: '0 20px 10px 20px',
		display: 'flex',
		justifyContent: 'center'
	},
	image: {
		width: '100%'
	},
	step: {
		// border: 'solid 1px black',
		color: 'white',
		backgroundColor: '#000',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		backgroundImage: `url(${podiumImage})`,
		fontWeight: 600
	}
}));


function Item(props) {
	const classes = useStyles();
	const comics = (props.comics || []);
	//console.log(props);
	return (
		<div className={classes.item}>
			<div className={classes.comic} >
				{comics.map((comic) => <Comic
					key={comic.id}
					comic={comic}
					raised
					open={props.open}
					onClick={() => { window.location.href = comic.url; }}
				/>)}
			</div>
			<div className={classes.step} style={{ height: (80 + (3 - props.rank) * 30) + 'px' }}>
				<h1>{props.rankLabel}</h1>&nbsp;&nbsp;
				<div>{(props.votes && props.votes > 0) ? (((comics[0] ? comics[0].votes : 0) * 100 / props.votes).toFixed(1)+'%') : ''}</div>
			</div>
		</div>
	);
}

export default function Podium(props) {
	// const isXS = useMediaQuery('(max-width:600px)');
	const classes = useStyles();

	const votes = props.comics.reduce((tot, { votes }) => (tot + votes), 0);

	const first = [];
	const second = [];
	const third = [];

	for (const comic of props.comics) {
		//console.log(comic);
		if (!first.length) {
			first.push(comic);
		} else if (comic.votes === first[0].votes) {
			first.push(comic);
		} else if (!second.length) {
			second.push(comic);
		} else if (!third.length) {
			third.push(comic);
		}
	}

	return <div className={classes.podium}>
		<Item rankLabel={first.length > 0 ? first.length + 1 : 2} rank={2} comics={second} votes={votes} />
		<Item rankLabel={1} rank={1} comics={first} votes={votes} />
		<Item rankLabel={first.length > 0 ? first.length + 2 : 3} rank={3} comics={third} votes={votes} />
	</div>;
}

Podium.defaultProps = {
	comics: []
};
