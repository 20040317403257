/* eslint-disable react/no-deprecated */
import React, { Component } from 'react';

import { UserContext } from '../../contexts';
import { postVote } from '../../utils/backend';

function parseQuery() {
	const string = (window.location.search || '?').slice(1);
	const pieces = string.split('&');
	const params = {};
	for (const piece of pieces) {
		const [key, value] = piece.split('=');
		params[key] = value;
	}
	return params;
}

function Login() {
	return (<UserContext.Consumer>{({ getUserInformations }) => {
		const params = parseQuery();
		setTimeout(() => {
			getUserInformations(params.code)
			.then((token) => {
				const useVote = localStorage.getItem('next_vote');
				localStorage.removeItem('next_vote');
				if (useVote) postVote(useVote, token);
			});
		}, 100);
		return <div>redirecting...</div>;
	}}</UserContext.Consumer>);
}

export default Login;
