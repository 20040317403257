import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'class-names';
import {
	useMediaQuery,
	Grid,
	Modal,
	IconButton,
	Dialog,
	CircularProgress
} from '@material-ui/core';

import AmazonButton from '../AmazonButton';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles((theme) => ({
	dialog: {
		overflowY: 'auto',
		'-webkit-overflow-scrolling': 'touch'
	},
	container: {
		height: '100%',
		width: '100%',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center'
	},
	paper: {
		display: 'flex',
		// position: 'absolute',
		// maxWidth: 900,
		backgroundColor: theme.palette.background.paper,
		// border: '2px solid #000',
		// boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
		flexDirection: 'row-reverse',
		// justifyContent: 'center',
		alignItems: 'center'
	},
	paperXS: {
		flexDirection: 'column'
		// width: 100%
	},
	info: {
		display: 'flex',
		flexDirection: 'column',
		alignSelf: 'flex-start',
		justifyContent: 'space-between',
		marginLeft: '10px'
	},
	infoXS: {
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center'
	},
	image: {
		'& > *': {
			width: 'auto!important'
		}
	}
}));

function title(_title) { return <h2 id='modal-title' style={{ marginBottom: '5px' }} >{_title}</h2>; }
function author(_author) { return <h5 id='modal-title' style={{ marginTop: '0px', fontStyle: 'italic' }}>{_author}</h5>; }
function description(_description) { return <p id='modal-description' style={{ wordBreak: 'break-word' }}>{_description}</p>; }
function image(comic) {
	return <img
		src={`/static/images/${comic.id}.jpg`}
		alt={comic.title}
		style={{ maxWidth: '400px', width: '100%', backgroundColor: 'rgb(255 255 255 / 15%)' }}
	/>;
	/* return comic.image ?
		<img
			// src={`data:image/jpeg;base64,${comic.image}`}
			alt={comic.title}
			style={{ maxWidth: '400px', width: '100%' }}
		/>
		: <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
			<CircularProgress style={{ color: '#f59618', marginBottom: '3px' }} />
			<div style={{ color: 'grey' }}>Caricamento immagine...</div>
		</div>; */
}
function actions(_actions) { return <div style={{ width: '100%' }}>{_actions}</div>; }

function close(onClick) {
	return (
		<IconButton
			aria-label='close'
			onClick={onClick}
			style={{
				position: 'absolute',
				top: '0px',
				right: '0px'
			}}
		>
			<CloseIcon />
		</IconButton>);
}

export default function ComicInfo(props) {
	const isXS = useMediaQuery('(max-width:850px)');
	const mqW = useMediaQuery('(max-width:600px)');
	const mqH = useMediaQuery('(max-height:600px)');
	const isXXS = mqW || mqH;

	const classes = useStyles();

	const comic = props.comic || {};

	return <Dialog
		open={props.open}
		onClose={props.onClose}
		aria-labelledby='modal-title'
		aria-describedby='modal-description'
		fullScreen={isXXS}
		maxWidth={'md'}
		className={classes.dialog}
	>
		{close(props.onClose)}
		{isXS ?
			<div className={classNames(classes.paper, classes.paperXS)} >
				<div className={classes.infoXS}>
					{title(comic.title)}
					{author(comic.author)}
					{image(comic)}
					{description(comic.description)}
					{actions(props.actions)}
				</div>
			</div> :
			<div className={classes.paper} >
				{/* {close(props.onClose)} */}
				<div className={classes.info}>
					{title(comic.title)}
					{author(comic.author)}
					{description(comic.description)}
					{actions(props.actions)}
				</div>
				<div className={classes.image}>
					{image(comic)}
				</div>
			</div>}
	</Dialog>;
}
